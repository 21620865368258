#kt_wrapper{
    // padding-top: calc(#{get($header-config, fixed, height, default) + $padding-large});
    #kt_content_container {
        padding: 0;
        max-width: 100%;
        position: relative;

        #kt_outlet {
            max-height: calc(100vh - 80px - 65px - 22px);
            overflow-y: scroll;
            overflow-x: hidden;
            position: relative;
        }
    }
}

#kt_login_signin_form h1 {
    color: #0090B8;
}
#kt_login_signin_form .form-label {
    margin-left: 1rem;
    margin-bottom: 10px;
}
#kt_login_signin_form .link-info {
    margin-top: 1rem; 
}
.help.is-danger {
    color: red;
    margin-left: 1rem;
} 

.table-cell-truncate {
    display: flex;
    justify-content: center;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
}

@include media-breakpoint-down(lg) {
    #kt_wrapper{
        #kt_content_container {
    
            #kt_outlet {
                max-height: calc(100vh - 250px);
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .auth-container {
        padding: 0 !important;
    }
}

@include media-breakpoint-down(s) {
    .login-container-buttons {
        flex-direction: column;
    }
    #kt_wrapper{
        #kt_content_container {
    
            #kt_outlet {
                max-height: calc(100vh - 200px);
            }
        }
    }
}