//
// Multistep stepper
//

// Custom
.stepper.stepper-pills.stepper-multistep {
    --kt-stepper-pills-size: 46px;
    --kt-stepper-icon-border-radius: 9px;
    --kt-stepper-icon-check-size: 1.25rem;

    --kt-stepper-icon-bg-color: rgba(255, 255, 255, 0.03);
    --kt-stepper-icon-bg-color-current: var(--kt-success);
    --kt-stepper-icon-bg-color-completed: rgba(255, 255, 255, 0.03);

    --kt-stepper-icon-border: 1px dashed rgba(255, 255, 255, 0.3);
    --kt-stepper-icon-border-current: 0;
    --kt-stepper-icon-border-completed: 1px dashed rgba(255, 255, 255, 0.3);

    --kt-stepper-icon-number-color: var(--kt-white);
    --kt-stepper-icon-number-color-current: var(--kt-white);
    --kt-stepper-icon-number-color-completed: var(--kt-white);
    
    --kt-stepper-icon-check-color-completed: var(--kt-success);

    --kt-stepper-label-title-opacity: 0.7;
    --kt-stepper-label-title-opacity-current: 1;
    --kt-stepper-label-title-opacity-completed: 1;

    --kt-stepper-label-title-color: var(--kt-white);
    --kt-stepper-label-title-color-current: var(--kt-white);
    --kt-stepper-label-title-color-completed: var(--kt-white);
    
    --kt-stepper-label-desc-opacity: 0.7;
    --kt-stepper-label-desc-opacity-current: 0.7;
    --kt-stepper-label-desc-opacity-completed: 0.7;

    --kt-stepper-label-desc-color: var(--kt-white); 
    --kt-stepper-label-desc-color-current: var(--kt-white);
    --kt-stepper-label-desc-color-completed: var(--kt-white);

    --kt-stepper-line-border: 1px dashed rgba(255, 255, 255, 0.3);
}

@include media-breakpoint-down(lg) {

    .stepper {
        &.stepper-order-details {
            display: none !important;
        }

        &.new-order-stepper {
            .mw-500px, .mw-350px {
                max-width: unset !important;
            }
        }
    }
}

@include media-breakpoint-down(sm) {

    .stepper {
        .step3 {
            & > div {
                display: block;

                .fields {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    display: block !important;
                }

                .btn {
                    margin-top: $padding-smaller;

                    &.remove-item {
                        margin-bottom: $padding-small;
                    }
                }
            }
        }
        .new-order-actions {
            flex-direction: column;
    
            & > div {
                width: 100%;
                margin-left: 0 !important;
                margin-right:  0 !important;
                margin-bottom: $padding-smaller;
    
                .btn {
                    max-width: 100%;
                    min-width:100%;
                    width: 100%;
                }
            }
        }
    }
}