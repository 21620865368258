.short-table {
    width: 46.66%;
    border-radius: $border-radius;
    border: solid 1px $border-container;
    background-color: white;
    padding: $padding-small $padding-large;

    .short-table-header {
        display: flex;

        .st-icon {
            margin-right: $padding-large;
        }

        h4 {
            margin: 0;
            color: $primary;
        }
    }

    .short-table-item {
        display: flex;
        width: 100%;
        margin-bottom: 3px;
        line-height: normal;
        font-size: $h6-font-size;
        padding: $padding-smaller $padding-large;
        justify-content: space-between;

        &:nth-child(even) {
            background-color: $list-item-even;
        }
        &:nth-child(odd) {
            background-color: $list-item-odd;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &.notifications {
        padding: 0;

        .short-table-header, .short-table-body {
            padding-top: $padding-30;
            padding-bottom: $padding-small;
            padding-left: $padding-largest;
            padding-right: $padding-30;
        }
        .short-table-body {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &.announcements {
        .description {
            text-decoration: underline;
        }
    }
}

@include media-breakpoint-down(lg) { 
    .short-table {
        width: 100%;
        margin-top: 0;

        .short-table-item {
            flex-wrap: wrap;

            > div:not(.date) {
                order: 1;

                .user {
                    margin-right: $padding-smaller !important;
                }
            } 

            .date {
                width: 100%;
                text-align: right;
                margin-bottom: $padding-smallest;
            }
        }
    }
}

@include media-breakpoint-down(md) { 
    .short-table {
        width: 100% !important;
        margin-left: 0 !important;
        
        &:not(.announcements) {
            margin-top: $padding-large;
        }

        .short-table-body {
            h4 {
                margin-top: 0 !important;
                margin-bottom: $padding-large;
            }
        }
    }
}

@include media-breakpoint-down(s) { 
    .short-table {

        &.notifications {
            padding: 0;
    
            .short-table-header {
                padding-top: $padding-30;
                padding-bottom: $padding-small;
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        .short-table-header {
            flex-direction: column;

            a {
                margin-top: $padding-smaller;
            }
        }

        .short-table-item {
            > div:not(.date) {
                .username {
                    padding-left: 0 !important;
                }
            }
        }
    }
}
