//
// Menu
//

// Aside menu
.aside-menu {
    .hover-scroll-overlay-y {
        overflow: hidden !important;
        --kt-scrollbar-space: 0.4rem;
        @include scrollbar-color(#3b3b64, lighten(#3b3b64, 3%));
    }

    // Basic
    .menu {
        .menu-section {
            color: #4c4e6f !important;
        }

        > .menu-item {
            > .menu-icon {					
                justify-content: center;
                margin-bottom: 0;                  

                i {                        
                    font-size: 22px !important;				
                }					
            }
			> .menu-link {
				
                height: auto;
                margin: 0; 
                flex-direction: row;
                // @include border-radius($border-radius);     

                .menu-title {					
					flex-grow: 0;          
				}   
			}             

            //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color, $all-links
            @include menu-link-default-state( $primary, $white, #3A3A5D, #3A3A5D, null, false);
            @include menu-link-hover-state( $primary, $white, var(--kt-primary), var(--kt-primary), null, false);
            @include menu-link-here-state( $primary, $white, var(--kt-primary), var(--kt-primary), null, false);
            @include menu-link-show-state( $primary, $white, var(--kt-primary), var(--kt-primary), null, false);
            @include menu-link-active-state( $primary, $white, var(--kt-primary), var(--kt-primary), #1e1e2d , false);
		}
    }     
}