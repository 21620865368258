//
// Header
//

// Desktop mode
@include media-breakpoint-up(lg) {
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: get($header-config, default, height, desktop);
		margin-bottom: $padding-large;

		// Fixed header & sticky header modes
		.header-fixed & {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: get($header-config, fixed, z-index);
			background-color: var(--kt-header-fixed-bg-color);
			height: get($header-config, fixed, height, desktop);
			box-shadow: var(--kt-header-header-fixed-box-shadow);
			padding: 0;
		}

		// Fixed header and fixed toolbar modes
		.header-fixed.toolbar-fixed & {
			box-shadow: none;
		}

		// Aside default enabled, aside fixed & header sticky modes
		.aside-enabled.aside-fixed.header-fixed & {
			left: calc(#{get($aside-config, width, desktop)} + #{$padding-largest}); //108px = 68 + 40
			padding-top: 40px;
			max-width: calc(#{$max-width-layout} - #{($padding-largest * 2)} - #{get($aside-config, width, desktop)} - #{$padding-largest}); 
			margin: auto;
			width: calc(100% - #{($padding-largest * 2)} - #{get($aside-config, width, desktop)} - #{$padding-largest});
		}

		//container
		.container-fluid {
			background-color: white;
			border-radius: $border-radius;
			border: solid 1px $border-container;
			padding-top: $padding-large;
			padding-bottom: $padding-large;
		}

		// Header menu
		.header-menu {
			display: flex;

			.menu {
				// Menu root item
				> .menu-item {
					@include menu-link-here-state( 
						$title-color: var(--kt-primary), 
						$icon-color: var(--kt-primary), 
						$bullet-color: var(--kt-primary), 
						$arrow-color: var(--kt-primary), 
						$bg-color: var(--kt-menu-link-bg-color-active)
					);
				}	
			}
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: get($header-config, default, height, tablet-and-mobile);

		// Fixed header and header sticky modes
		.header-tablet-and-mobile-fixed & {
			position: fixed;
			top: $padding-30;
			right: $padding-30;
			left: $padding-30;
			border-radius: $border-radius;
			z-index: 110;
			height: get($header-config, fixed, height, tablet-and-mobile);
			min-height: get($header-config, fixed, height, tablet-and-mobile);
			background-color: var(--kt-primary);
			box-shadow: var(--kt-header-fixed-box-shadow);

			#kt_header_nav {
				background-color: #fff;
				border: 1px solid #e8e8e8;
				border-radius: 12px;
				left: 0;
				padding: 24px;
				position: absolute;
				top: 71px;
			}
		}

		#logo {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		#kt_aside_mobile_toggle {
			position: absolute;

			path {
				opacity: 1 !important;
				fill: #fff !important;
			}
		}

		// Fixed header and fixed toolbar modes
		.header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
			box-shadow: none;
		}

		// Header menu
		.header-menu {
			display: none;
			z-index: get($header-config, fixed, z-index) - 1;
		}

		// Page title
		.page-title {
			display: none !important;
		}

		#kt_header_user_menu_toggle {
			display: none !important;
		}
	}
}

@include media-breakpoint-down(s) {
	.header {
		// Fixed header and header sticky modes
		.header-tablet-and-mobile-fixed & {
			top: 20px;
			right: 20px;
			left: 20px;
		}
	}
	#kt_aside_mobile {
		width: calc(100% - 40px) !important; 
	}
}


