//
// Main
//

// Body
body {
	padding: $padding-largest;
	padding-bottom: 0;
	background-color: var(--kt-page-bg);
}

// Font color from Content background color
.text-page-bg {
	color: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		padding: 0 get($content-spacing, desktop);
	}

	// Wrapper
	.wrapper {
		// Aside default enabled and aside fixed modes
		.aside-enabled.aside-fixed & {
			padding-left: calc(#{get($aside-config, width, desktop) + $padding-largest});
		}

		// Fixed header mode
		.header-fixed & {
			padding-top: get($header-config, fixed, height, desktop);
		}

		// Fixed header & fixed toolbar modes
		.header-fixed.toolbar-fixed & {
			padding-top: calc(#{get($header-config, fixed, height, default)} + #{$padding-large}); 
		}

		&.no-header {
			padding-top: 0 !important;

			#kt_aside {
				top: 0;
				height: 100%;
			}

			.header {
				display: none !important;
			}
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {

	body { 
		padding: $padding-30;
	}

	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		max-width: none;
		padding: 0 get($content-spacing, tablet-and-mobile);
	}

	// Wrapper
	.wrapper {
		// Fixed header mode
		.header-tablet-and-mobile-fixed & {
			padding-top: calc(#{get($header-config, fixed, height, tablet-and-mobile) + 110px});
		}

		// Fixed header & fixed toolbar modes
		.header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
			padding-top: get($header-config, fixed, height, tablet-and-mobile) + get($toolbar-config, height, tablet-and-mobile);
		}

		&.no-header {
			padding-top: 100px !important;
			#kt_header_nav {
				display: none !important;
			}
		}
	}
}

@include media-breakpoint-down(s) {
	body { 
		padding: 20px;
	}
	// Wrapper
	.wrapper {
		// Fixed header mode
		.header-tablet-and-mobile-fixed & {
			padding-top: calc(#{get($header-config, fixed, height, tablet-and-mobile) + 120px});
		}
	}
}
