.board-center {
    text-align: center;
}
.board-state-text {
    color: #0090B8;
}
.board-white-text {
    color: #192939;
}
.board-week-text {
    color: #a3d5d8;
}
.board-background {
    flex: 1;
    flex-basis: 33%;
    padding: 20px;
    overflow-y: auto;
}
.board-dates {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}