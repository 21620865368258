html, body {
    font-size: 16px !important;
}
.hero, .fs-hero {
    font-size: $hero-fontSize !important;
}
h1, .h1, .fs-1 {
    font-size: $h1-font-size !important;
}
h2, .h2, .fs-2{
    font-size: $h2-font-size !important;
}
h3, .h3, .fs-3 {
    font-size: $h3-font-size !important;
}
h4, .h4, .fs-4 {
    font-size: $h4-font-size !important;
}
h5, .h5, .fs-5 {
    font-size: $h5-font-size !important;
}
h6, .h6, .fs-6 {
    font-size: $h6-font-size !important;
}
.form-control {
    font-size: $h5-font-size;
    height: 35px;
    min-height: 35px !important;
}
.form-control.form-control-solid:focus {
    border-color: black;
}
.button-container-actions {
    display: flex;
    justify-content: center;
    padding-top: $padding-largest;
    padding-bottom: $padding-large;

    .btn {
        margin: 0 20px;
        width: 141px;
        border-radius: 100px;
    }
}
button.btn {
    min-width: 141px;
    max-width: 141px;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 35px;
    font-weight: 400 !important;
    font-size: $h5-font-size !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}

button[type="button"].btn, button[type="submit"].btn, a.btn:not(.btn-active) {
    border-radius: 100px;
    &.btn-primary {
        &:hover {
            background-color:  #0090B8 !important;
        }
    }
}
.table-cell-trucate {
    display: -webkit-box;
    max-width: 200px;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.border-gray {
    border: solid 1px $border-container !important;
}
.card-qty {
    padding: 3px 10px;
    padding-top: 5px;
    font-size: $h5-font-size;
    background-color: $card-qty;
}
.search-box {
    height: fit-content;
    max-width: 350px;
    min-height: 30px;
    background-color: $searchBox-bg;

    input[type="search"] {
        width: calc(100% - 40px);
        margin-top: 1px;
        padding-left: 1rem;
        border: none !important;
        background-color: transparent;
    
        &:focus {
            box-shadow: unset !important;
            outline: unset !important;
            border: none !important;
        }
    }

    #resetFilter {
        margin-right: -100px;

        &:hover {
            text-decoration: underline !important;
        }
    }

    .search-icon {
        padding: 0 $padding-smaller;
        margin-right: 20px;
        position: relative;

        &:hover {
            cursor: pointer;

            &::before {
                content: '';
                background: #0000001a;
                width: 1.75rem;
                height: 1.75rem;
                display: inline-block;
                position: absolute;
                top: -3px;
                left: 7px;
                border-radius: 50%;
            }
        }
    }
}
.back-arrow {
    z-index:10;

    &:hover {
        h5 {
            font-weight: 700 !important;
        }
    }
}

.MuiDataGrid-cell[data-field="county"] {
    white-space: pre;
}

@include media-breakpoint-down(lg) {
    .search-box {
        width: 100% !important;
        max-width: unset;
    }

    .back-arrow {
        margin-bottom: $padding-large;
        position: relative !important;
        flex-direction: row !important;
        justify-content: flex-start !important;
    }
}

@include media-breakpoint-down(s) {

    button.btn {
        min-width: 100%;
        max-width: 100%;
    }

    .button-container-actions {
        flex-direction: column;

        .btn {
            margin: 0;

            &.btn-cancel {
                margin-bottom: $padding-smaller;
            }
        }
    }
}