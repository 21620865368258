.checkbox-group {
    display: flex;
    flex-direction: column;
}
.checkbox-item {
    margin: 20px 0;
    display: flex;
    align-items: center;

    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: $h5-font-size;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
      

    input[type=checkbox] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked  {
            opacity: 1;
        }
    }

    input[type=checkbox]:checked + .checkmark {
        background-color: var(--kt-primary);
        i {
            display: block;
        }
    }

    .checkmark {
        position: absolute;
        top: 1px;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: $white;
        border: solid 2px var(--kt-primary);

        i {
            color: white;
            display: none;
            font-size: $h6-font-size;
        }
    }
}