.three-dot-menu{
  padding-top: 6px;
  margin: 0 10px;
}
.menu-icon {
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
}

.dot {
  width: 6px;
  height: 6px;
  background-color: #333;
  border-radius: 50%;
  display: block;
  position: absolute;
}

.dot:nth-child(1) {
  top: 0;
  left: 8px; 
}

.dot:nth-child(2) {
  top: 8px;
  left: 8px;
}

.dot:nth-child(3) {
  top: 16px;
  left: 8px; 
}
.menu-options {
  position: absolute; 
  background-color: #fff; 
  border: 1px solid #ccc; 
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 
  z-index: 9999; 
  display: flex; 
  flex-direction: column; 
  padding: 10px; 
  right: 60px;
}

.menu-option {
  cursor: pointer;
  padding: 10px 10px;
  border-bottom: 1px solid #ccc;
}
.menu-header {
  border-bottom: 1px solid #ccc;
}

.disabled{
  color: #3F4254;;
  background-color: #E4E6EF;
}
.disabled:hover{
  color: #3F4254;;
  background-color: #E4E6EF;
}

.menu-option:hover {
  background-color: #f2f2f2; 
}
.mr-icon{
  margin-right: 20px;
}
