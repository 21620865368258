#kt_topbar {
    #kt_header_user_menu_toggle {
        .title {
            padding-left: $padding-large;

            h2 {
                margin-bottom: 0;
                font-weight: 400;
                color: $primary-light;
            }
        }
    }

    .user-info {
        .username > a {
            padding: 0 2px;

            &:hover {
                text-decoration: underline !important;
            }
        }
        .date {
            margin: 0 $padding-larger 0 32px;
        }
    }
}

@include media-breakpoint-down(lg) {

    .topbar-data {
        width: 100%;

        .menu-icon {
            position: absolute;
            right: 0;
            margin-right: 150px !important;
        }

        .user-info {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
}

@include media-breakpoint-down(s) {
	.topbar-data {

        .menu-icon {
            margin-right: 50px !important;
            top: 42px;
        }

        .user-info {
            flex-wrap: wrap;

            &>.username, &>div {
                width: 100%;
                justify-content: space-between;
            }

            .date {
                margin: 0 !important;
            }
        }
    }
}