.chatbox {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    overflow-y: auto;
    min-width: 100%;
    min-height: 100%;
}

.message {
    display: flex;
    width: 50%;
    margin: 10px;
    flex-direction: column;
    padding: 10px;
    text-transform: capitalize;
}

.message-self {
    margin-left: auto;
}
.message-other {
    margin-right: auto;
}

.sender {
    font-size: 0.8rem;
}

.sender-self {
    margin-left: auto;
}

.sender-other {
    margin-right: auto;
}

.row-self {
    flex-direction: row;
}

.row-other {
    flex-direction: row-reverse;
}