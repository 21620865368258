//
// Aside Default
//

// General mode(all devices)
.aside {
	display: flex;
	flex-direction: column;
	box-shadow: var(--kt-aside-box-shadow);
	background-color: var(--kt-aside-bg-color);
	padding: 0;	
	border-radius: get($aside-config, border-radius);
	max-height: calc(100vh - 80px);
	min-height: 550px;
	height: calc(100% + 88px);
	transition: all 0.5s ease !important;
	cursor: pointer;
	background: linear-gradient(to right, #0090B8 68px, #FFF 0px);

	.expand-aside-icon {
		top: 70px;
		right: -22px;
		position: absolute;
	}

	&.open {
		// height: calc(100vh - 80px);

		.menu-title {
			opacity: 1 !important;
		}

		.expand-aside-icon {
			transform: scaleX(-1);
		}
	}
}

// Desktop mode
@include media-breakpoint-up(lg) {
	#kt_aside_mobile {
		display: none !important;
	}
	.aside {
		width: get($aside-config, width, desktop);

		&.open {
			width: calc(#{get($aside-config, width, desktop) + get($aside-config, width, options)});
		}

		// Logo
		.aside-logo {
			width: get($aside-config, width, desktop);
			display: flex;
			justify-content: center;
			align-items: flex-start;
			background-color: var(--kt-aside-logo-bg-color);
			height: get($aside-config, logo-height);
			padding: 0 get($aside-config, padding-x);
			flex-shrink: 0;
			border-radius: $border-radius;
		}

		// Aside footer
		.aside-footer {
			display: flex;
			align-items: center;
		}
		
		// Fixed aside mode
		.aside-fixed & {
			position: absolute;
			bottom: 0;
			left: -108px;
			top: calc(-#{get($header-config, fixed, height, default)} - #{$padding-large});
			z-index: get($aside-config, z-index);
		}

		// Static aside mode
		.aside-static & {
			position: relative;
			z-index: 1;
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	#kt_aside {
		display: none !important;
	}
	.aside {
		display: none !important;
		left: 30px !important;
		width: 100% !important;
		top: 77px;
		min-width: unset !important;
		max-width: unset !important;
		background: var(--kt-primary);
		height: fit-content !important;
		min-height: unset !important;
		max-height: unset !important;
		width: calc(100vw - 60px) !important;
		padding-top: 0 !important;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		z-index: 110;
		transform: none !important;

		&.open {
			display: block !important;
		}

		&.drawer-on {
			display: block !important;
		}

		// Logo
		.aside-logo {
			display: none;
		}

		.aside-menu {
			padding: $padding-small $padding-large;
			flex-direction: column;

			a {
				border-color: #eff2f554 !important;
			}
		}
	}
}

@include media-breakpoint-down(s) {
	.aside {
		display: none !important;
		left: 20px !important;
		width: 100% !important;
		top: 67px;

	}
}