//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors

// Dark colors
$dark-dark: 										#000000; 
$table-color-dark:                          		#000000;
$headings-color-dark: 								#000000;



// Primary
$primary:       									 #0090B8;
$primary-active:    								$primary;
$primary-light:    								    #0090B8;
$primary-light-dark:    							#212e48;
$primary-inverse:  									#ffffff;
$primary-newdesign: 								#0090B8;
// Success
$success:       									#E3F5FF;
$success-active:    								#47be7d;
$success-light:    									#e8fff3;
$success-light-dark:    							#1c3238;
$success-inverse:  									#ffffff;

// Info
$info:       									    #7239ea;
$info-active:    									#5014d0;
$info-light:    									#f8f5ff;
$info-light-dark:    								#2f264f;
$info-inverse:  									#ffffff;

// Danger
$danger:       									    #f1416c;
$danger-active:    									#d9214e;
$danger-light:    									#fff5f8;
$danger-light-dark:    								#3a2434;
$danger-inverse:  									#ffffff;

// Warning
$warning:       									#ffc700;
$warning-active:    								#f1bc00;
$warning-light:    									#fff8dd;
$warning-light-dark:    							#392f28;
$warning-inverse:  									#ffffff;

//list items
$list-item-even: 									#F2F9F9;
$list-item-odd: 									#FBFBFC;

// Card Box Shadow
$card-box-shadow:                                   0px 0px 20px 0px rgba(76,87,125,0.02);
$card-box-shadow-dark:                              0px 0px 20px 0px rgba(76,87,125,0.02);



//CUSTOM

$max-width-layout: 									1700px;

$body-bg:                                           #F9F9F9;
$page-bg:                                           $body-bg;
$font-family-sans-serif:                            'Open Sans', sans-serif;

$hero-fontSize: 									15vw;
$h1-font-size:                                      30px; // 22.75px
$h2-font-size:                                      24px; // 19.50px
$h3-font-size:                                      20px; // 17.55px
$h4-font-size:                                      18px; // 16.25px
$h5-font-size:                                      14px; // 14.95px
$h6-font-size:                                      12px; // 13.97px
$font-size-base:                                    1rem !default;
$tiny-font-size: 									10px; 

$input-bg:                                          transparent !important;
$input-border-radius-lg:                            5px;
$input-solid-bg:                                    #2B2B2B;

$card-qty: 											#9A9A9A;

$searchBox-bg: 										#E3F5FF;
$table-head: 										#f6f6f6;

$link-color:                                        #0089E5;

$border-container:                                  #E8E8E8;
$border-table: 										#afafaf;
$border-table-rows: 								#DFDFDF;
$border-radius:                                     12px;

$padding-30: 										 30px;
$padding-50: 										 50px;	
$padding-largest:                                    40px;
$padding-larger:                                     24px;
$padding-large:                                      22px;
$padding-small:                                      16px;
$padding-smaller:                                    12px; 
$padding-smallest:                                   10px; 


$menu-link-padding-x:                                0;
$menu-link-padding-y:                                0;

// Aside
$aside-config: (
	z-index: 101, // Aside's z-index property
	padding-x: 25px,
	menu-indention: 0.75rem,
	bg-color: $primary-newdesign,
	logo-bg-color: $primary-newdesign,
	width: (
		desktop: 68px,
		tablet-and-mobile: 80px,
		options: 100px
	), // Aside width for desktop mode
	logo-height: 100px, // Aside logo height
	box-shadow: 0 0 28px 0 rgba(82,63,105,.05), // Aside box shadow
	box-shadow-dark: none, // Aside box shadow
	left: $padding-largest,
	top: $padding-largest,
	bottom: $padding-largest,
	border-radius: $border-radius,
	border-width: 3px
);

$header-config: (
	// Default Mode
	default: (
		height: (
			desktop: 65px,
			tablet-and-mobile: 55px
		),
		bg-color: $primary,
		bg-color-dark: #1E1E2D,
	),

	// Fixed Mode
	fixed: (
		height: (
			default: 68px,
			desktop: calc(68px + $padding-largest),
			tablet-and-mobile: 55px
		),
		z-index: 100,
		bg-color: $body-bg,
		bg-color-dark: #1E1E2D,
		box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.05),
		box-shadow-dark: 0px 10px 30px 0px rgba(82,63,105,0.05)
	)
);

// Keenthemes hight and width sizes
$custom-sizes: (
	unset: unset,
	25: 25%,
	50: 50%,
	75: 75%,
	100: 100%,
	auto: auto,
	1px: 1px,
	2px: 2px,
	3px: 3px,
	4px: 4px,
	5px: 5px,
	6px: 6px,
	7px: 7px,
	8px: 8px,
	9px: 9px,
	10px: 10px,
	15px: 15px,
	20px: 20px,
	25px: 25px,
	29px: 29px,
	30px: 30px,
	35px: 35px,
	38px: 38px,
	40px: 40px,
	45px: 45px,
	50px: 50px,
	55px: 55px,
	60px: 60px,
	65px: 65px,
	70px: 70px,
	75px: 75px,
	80px: 80px,
	85px: 85px,
	90px: 90px,
	95px: 95px,
	100px: 100px,
	125px: 125px,
	150px: 150px,
	175px: 175px,
	200px: 200px,
	225px: 225px,
	250px: 250px,
	275px: 275px,
	300px: 300px,
	325px: 325px,
	350px: 350px,
	375px: 375px,
	400px: 400px,
	425px: 425px,
	450px: 450px,
	475px: 475px,
	500px: 500px,
	550px: 550px,
	600px: 600px,
	650px: 650px,
	700px: 700px,
	750px: 750px,
	800px: 800px,
	850px: 850px,
	900px: 900px,
	950px: 950px,
	1000px: 1000px
);


// Keenthemes symbol component
$symbol-sizes: (
	20px: 20px,
	25px: 25px,
	30px: 30px,
	35px: 35px,
	40px: 40px,
	45px: 45px,
	default: 50px,
	50px: 50px,
	55px: 55px,
	60px: 60px,
	65px: 65px,
	70px: 70px,
	75px: 75px,
	100px:100px,
	125px:125px,
	150px:150px,
	160px:160px,
	175px:175px,
	200px:200px
);

$font-sizes: (
	1: $h1-font-size,   // 22.75px
	2: $h2-font-size,   // 19.50px
	3: $h3-font-size,   // 17.55px
	4: $h4-font-size,   // 16.25px
	5: $h5-font-size,   // 14.95px
	6: $h6-font-size,   // 13.95px

	7: $font-size-base * 0.95,  // 12.35px
	8: $font-size-base * 0.85,  // 11.05px
	9: $font-size-base * 0.75,  // 9.75px
	10: $font-size-base * 0.5,  // 6.50px

	29: 29px,
	40: 40px,

	base: $font-size-base, // 13px
	fluid: 100%, // 100%

	2x: $font-size-base * 2,  // 26px
	2qx: $font-size-base * 2.25, // 29.25px
	2hx: $font-size-base * 2.5,  // 32.5px
	2tx: $font-size-base * 2.75, // 35.75px

	3x: $font-size-base * 3,  // 39px
	3qx: $font-size-base * 3.25, // 42.25px
	3hx: $font-size-base * 3.5,  // 45.5px
	3tx: $font-size-base * 3.75, // 48.75px

	4x: $font-size-base * 4,  // 52px
	4qx: $font-size-base * 4.25, // 55.25px
	4hx: $font-size-base * 4.5,  // 58.5px
	4tx: $font-size-base * 4.75, // 61.75px
	
	5x: $font-size-base * 5,  // 65px
	5qx: $font-size-base * 5.25, // 68.25px
	5hx: $font-size-base * 5.5,  // 71.5px
	5tx: $font-size-base * 5.75,  // 74.75px

    user: 35px,
	comingSoon: clamp(4rem, 4vw, 7rem),
	hero: 10vw
);

$grid-breakpoints: (
	xs: 0,
	s:  420px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px
);