.card {
    &.order-details {
        margin-top: $padding-large;
    }
    &.new-order-button {
        display: none;
    }
    &.new-order {
        padding: 0 $padding-largest $padding-largest;

        .card-header {
            min-height: unset;
            padding: $padding-large 0;
        }
        .card-body {
            padding: $padding-large 0 $padding-largest 0;

            .step2 {
                .col-md-12 {
                    margin-bottom: $padding-large;
                }
            }

            .step4 {
                h1 {
                    padding-top: $padding-50;
                    padding-bottom: $padding-largest
                }

                .details {
                    &:not(:first-of-type) {
                        border-left: solid 1px $border-container !important;
                    }
                    &.order-dates {
                        padding-left: 5%;
                        padding-right: 6%;
                    }
                    &.owner-info {
                        padding-right: 7.85%;
                    }
                    &.closing-date {
                        padding-left: 1.45%;
                        padding-right: 5%;
                    }
                    &.transaction-type {
                        padding-left: 2.85%;
                    }
                }

                .product {
                    padding-top: 3.5%;
                }
            }
        }
    }
}
.order-status {
    margin-top: $padding-50;
}

.order-details-nav {
    position: fixed;
    max-width: calc(#{$max-width-layout} - #{($padding-largest * 2)} - #{get($aside-config, width, desktop)} - #{$padding-largest}); 
    margin: auto;
    width: calc(100% - #{($padding-largest * 2)} - #{get($aside-config, width, desktop)} - #{$padding-largest});
    background-color: var(--kt-body-bg);
    z-index: 99;
    padding-bottom: 1rem;
}

@include media-breakpoint-up(lg) {
    .order-details-nav + div {
        margin-top: 100px !important;
    }
}


@include media-breakpoint-down(lg) {
    .order-status {
        margin-top: $padding-large;
    }

    .order-details-nav {
        width: 100%;
        position: unset;
    }

    .card {
        &.new-order {
            padding: 0 $padding-30 $padding-30;

            .card-body {
                .step4 {
                    h1 {
                        padding-top: 0;
                        padding-bottom: $padding-smaller;
                    }

                    .order-details {
                        flex-direction: column;
        
                        .details {
                            padding-left: 0 !important;
                            border-left: unset !important;
        
                            &.order-dates {

                                .expected-date {
                                    .fw-normal {
                                        display: inline-block;
                                        margin-bottom: $padding-smallest;
                                        padding-bottom: $padding-smallest;
                                        border-bottom: solid 1px $border-container;
                                    }
                                }
                            }
                        }
                    }
                }
            }

           
        }

        &.new-order-card {
            .card-toolbar, .card-title {
                display: none;
            }
            .search-box {
                width: 100% !important;
                max-width: unset;
            }
        }
        &.new-order-button {
            display: flex;
            padding: $padding-30;
            margin-top: $padding-larger;

            .card-toolbar {
                display: flex;
                justify-content: center;

                a.btn {
                    width: 231px;
                }
            }
        }
        &.order-details {
            margin-top: 0;

            .transaction-info {
                grid-template-columns: repeat(1, 100%) !important;

                > * {
                    margin: 0 !important;
                }
            }
             
            .card-header {
                border-bottom: unset !important;
            }

            .order-status-title {
                display: block !important;

                & + div {
                    flex-wrap: wrap;
                    justify-content: flex-start !important;

                    > div {
                        width: 100%;
                    }

                    .order-delivery {
                        order: 1;
                        padding-top: $padding-largest;
                        padding-bottom: $padding-small;
                        flex-direction: row !important;
                        align-items: center !important;
                        justify-content: flex-start !important;

                        h5 {
                            margin-left: $padding-smallest;
                        }
                    }

                    .order-details {
                        flex-direction: column;

                        > div {
                            width: 100%;
                            border-left: unset;
                            padding: 0 !important;

                            .order-date, .expected-date, .product, .transaction-type {
                                padding: calc(#{$padding-smallest}/2) 0;
                            }

                            .expected-date {
                                padding-bottom: $padding-smallest;

                                h5.fw-light {
                                    padding-bottom: $padding-smallest;
                                    border-bottom: solid 1px $border-container;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}