.top-cards {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 120px;
    width: calc(100% - 80px - 68px - 40px);
    max-width: calc(1700px - 80px - 68px - 40px);
    z-index: 99;
    padding-bottom: 1rem;
    background-color: var(--kt-body-bg);

    .top-filter {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        > div {
            width: 19%;
            // width: 31%;
        }
    
        .card {
            height: 100% !important;
            width: 100%;
            border: solid 1px $border-container;
            border-radius: $border-radius;
            padding-left: 0;
            padding-right: 0;

            &:hover {
                background-color:  var(--kt-success) !important;
            }
    
            .card-body {
                padding: $padding-small;

                .expand-icon {
                    float: right;
                }
            }
        }
    }
}

.top-cards ~ .dashboard-table {
    margin-top: 180px;
}

@include media-breakpoint-down(lg) {
    .top-cards {
        overflow-x: auto;
        width: 100%;
        position: sticky;
        top: 0px;
        
        .top-filter {
            //flex-direction: column;
            min-width: 100%;
            height: 100%;
            justify-content: space-between;
    
            > div {
                // width: 22.11%;
                width: 100%;
            }
        
            .card {
                height: 100% !important;
                width: 100%;
                border: solid 1px $border-container;
                border-radius: $border-radius;
                padding-left: 0;
                padding-right: 0;
        
                .card-body {
                    padding: $padding-small;
    
                    .expand-icon {
                        float: right;
                    }
                }
            }
        }
    }

    .top-cards ~ .dashboard-table {
        margin-top: 0;
    }
}